<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.instrumento.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.instrumento.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('Instrumento', 'Inserir')"
      :sem-filtro="!buscarPermissao('Instrumento', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <botao-padrao
      v-if="podeExcluir && buscarPermissao('Instrumento', 'Deletar')"
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>

    <data-table
      :rows="10"
      :scrollable="true"
      :paginator="false"
      data-key="id"
      :row-hover="true"
      :value="tabela.dados"
      :selection.sync="tabela.selecionados"
      show-gridlines
      class="p-datatable-sm tabela-prime-vue-inherit"
      responsive-layout="scroll"
      row-group-mode="subheader"
      group-rows-by="participanteCliente.nome"
      sort-mode="single"
      sort-field="participanteCliente.nome"
      :mostrar-seletor="buscarPermissao('Instrumento', 'Deletar')"
      :sort-order="1"
      :expandable-row-groups="true"
      :expanded-row-groups.sync="expandedRowGroups"
      @update:sortOrder="atualizarSentidoOrdenacao"
      @update:sortField="atualizarOrdenacaoCampo"
    >
      <template #header>
        <div class="d-flex justify-end">
          <span class="p-input-icon-left ml-100 mr-2">
            <i class="pi pi-search" />
            <InputText
              v-model="filtroGlobal"
              class="p-inputtext-sm"
              placeholder="Filtrar"
              @keydown.enter="filtrarGlobal"
            />
          </span>
          <botao-padrao
            outlined
            tooltip="Colunas"
            @click="abreModalDefineColunas"
          >
            <v-icon>$formatColumns</v-icon>
          </botao-padrao>
        </div>
      </template>
      <Column
        selection-mode="multiple"
        :styles="{ 'max-width': '3%' }"
      />
      <Column
        field="acoes"
        header=""
        :styles="{ 'max-width': '3%' }"
      >
        <template #body="slotProps">
          <dropdown-padrao
            v-if="mostrarDropDown"
            text
            color="secondary"
          >
            <template #botao>
              <v-icon> $dotsVertical </v-icon>
            </template>
            <dropdown-padrao-item
              v-if="buscarPermissao('Instrumento', 'Editar')"
              @click="abrirEditar(slotProps.data)"
            >
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
            <dropdown-padrao-item
              v-if="buscarPermissao('Instrumento', 'Editar')"
              @click="abrirPontosCalibracao(slotProps.data)"
            >
              {{ $t('modulos.instrumento.tabela.pontos_de_calibracao') }}
            </dropdown-padrao-item>
            <dropdown-padrao-item
              v-if="buscarPermissao('Instrumento', 'Duplicar')"
              @click="abrirModalDuplicarInstrumento(slotProps.data)"
            >
              {{ $t('modulos.instrumento.botoes.duplicar_instrumento') }}
            </dropdown-padrao-item>
            <dropdown-padrao-item
              v-if="
                !slotProps.data.flagAtivo &&
                  buscarPermissao('Instrumento', 'Ativar')
              "
              @click="statusInstrumento(slotProps.data, true)"
            >
              {{ $t('modulos.instrumento.botoes.ativar') }}
            </dropdown-padrao-item>
            <dropdown-padrao-item
              v-if="buscarPermissao('Instrumento', 'Ativar')"
              @click="statusInstrumento(slotProps.data, false)"
            >
              {{ $t('modulos.instrumento.botoes.inativar') }}
            </dropdown-padrao-item>
            <dropdown-padrao-item
              v-if="
                !slotProps.data.flagRevisadoPelaUnidadeNegocioMantenedora &&
                  $store.getters['Autenticacao/participanteLogadoConta']
              "
              @click="redirecionarRevisarInstrumento(slotProps.data)"
            >
              {{ $t('modulos.instrumento.botoes.revisar') }}
            </dropdown-padrao-item>
            <dropdown-padrao-item
              v-if="buscarPermissao('Instrumento', 'Visualizar')"
              @click="abrirCertificados(slotProps.data)"
            >
              {{ $t('modulos.instrumento.botoes.certificados') }}
            </dropdown-padrao-item>
            <dropdown-padrao-item
              v-if="buscarPermissao('Instrumento', 'VisualizarHistorico')"
              @click="abrirInstrumentoHistorico(slotProps.data)"
            >
              {{ $t('modulos.instrumento.botoes.historico') }}
            </dropdown-padrao-item>
            <dropdown-padrao-item
              v-if="buscarPermissao('Instrumento', 'VisualizarHistorico')"
              @click="abrirInstrumentoHistoricoServicosPecas(slotProps.data)"
            >
              {{ $t('modulos.instrumento.botoes.historico_servicos_pecas') }}
            </dropdown-padrao-item>
            <dropdown-padrao
              submenu
              text
              color="secondary"
            >
              <template #botao>
                <v-list-item-group
                  style="letter-spacing: normal; font-weight: 400"
                >
                  {{ $t('modulos.instrumento.botoes.outras_opecoes') }}
                </v-list-item-group>
              </template>
              <div @click="alterarDropDown">
                <dropdown-padrao-item
                  v-if="buscarPermissao('Instrumento', 'AlterarTecnico')"
                  @click="abrirModalTecnicoResponsavel(slotProps.data)"
                >
                  {{
                    $t('modulos.instrumento.botoes.alterar_tecnico_responsavel')
                  }}
                </dropdown-padrao-item>
                <dropdown-padrao-item
                  v-if="slotProps.data.flagVerificacaoIntermediaria"
                  @click="redirecionarVerificacaoIntermediaria(slotProps.data)"
                >
                  {{
                    $t('modulos.instrumento.botoes.verificacoes_intermediarias')
                  }}
                </dropdown-padrao-item>
                <dropdown-padrao-item
                  v-if="buscarPermissao('InstrumentoOcorrencia', 'Listar')"
                  @click="abrirModalRegistroOcorrencia(slotProps.data)"
                >
                  {{ $t('modulos.instrumento.botoes.registro_ocorrencia') }}
                </dropdown-padrao-item>
                <dropdown-padrao-item
                  v-if="
                    buscarPermissao(
                      'InstrumentoRegistroManutencaoTrocaPeca',
                      'Listar'
                    )
                  "
                  @click="
                    redirecionaRegistroManutencaoTrocaPeca(slotProps.data)
                  "
                >
                  {{
                    $t(
                      'modulos.instrumento.botoes.registrar_manutencao_troca_peca'
                    )
                  }}
                </dropdown-padrao-item>
                <dropdown-padrao-item
                  v-if="buscarPermissao('InstrumentoEmprestimo', 'Listar')"
                  @click="abrirModalMovimentacao(slotProps.data, 'Emprestimo')"
                >
                  {{ $t('modulos.instrumento.botoes.emprestimo_outro_setor') }}
                </dropdown-padrao-item>
                <dropdown-padrao-item
                  v-if="buscarPermissao('InstrumentoMovimentacao', 'Listar')"
                  @click="abrirModalMovimentacao(slotProps.data, 'Definitivo')"
                >
                  {{
                    $t('modulos.instrumento.botoes.movimentacao_outro_setor')
                  }}
                </dropdown-padrao-item>
                <dropdown-padrao-item
                  v-if="buscarPermissao('Instrumento', 'Visualizar')"
                  @click="abrirGraficoTendecia(slotProps.data)"
                >
                  {{ $t('modulos.instrumento.botoes.grafico_tendencia') }}
                </dropdown-padrao-item>
              </div>
            </dropdown-padrao>
          </dropdown-padrao>
        </template>
      </Column>

      <div :key="colunasKey">
        <column
          v-for="(col, index) of colunasSelecionadas"
          :key="col.field + '_' + index"
          :field="col.field"
          :header="col.header"
          :sortable="col.sortable"
          :tipo="col.tipo ?? 'string'"
          :styles="col.styles"
        >
          <template #body="slotProps">
            <slot
              v-if="col.field == 'faixa'"
              :slotProps="slotProps"
              :name="col.field"
            >
              {{ exibirFaixa(slotProps.data) }}
            </slot>
            <slot
              v-else
              :slotProps="slotProps"
              :name="col.field"
            >
              {{ formatarValorColuna(col, slotProps.data) }}
            </slot>
          </template>
        </column>
      </div>

      <template #groupheader="slotProps">
        <strong>
          {{ slotProps.data.participanteCliente.nome }}
          {{
            slotProps.data.numeroItens == 1
              ? `(${slotProps.data.numeroItens} item)`
              : `(${slotProps.data.numeroItens} itens)`
          }}
        </strong>
      </template>
    </data-table>
    <Paginator
      :rows.sync="itensPorpagina"
      :first.sync="paginaAtual"
      :total-records="tabela.quantidadeItens"
      :rows-per-page-options="[
        10, 25, 50, 100, 500]"
      current-page-report-template="({currentPage} de {totalPages})"
      template="JumpToPageInput FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
    />

    <DefineColunasModalPrimeVue
      ref="modal-define-colunas"
      :colunas="tabela.colunas"
      :selecionadas="novasColunas"
      @envia-colunas-selecionadas="refazColunasTabela"
    />
    <movimentacao-modal ref="modal-movimentacao" />
    <registro-ocorrencia-modal
      ref="modal-registro-ocorrencia"
      :codigo-tag="codigoTag"
      :id-instrumento="idInstrumento"
    />
    <duplicar-instrumento-modal
      ref="modal-duplicar-instrumento"
      @duplicarInstrumento="listarRegistros"
    />
    <tecnico-responsavel-modal ref="modal-tecnico-responsavel" />
    <ModalServicosPecas ref="modal-historico-servicos-pecas" />
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import InstrumentoService from '@common/services/cadastros/InstrumentoService.js';
import Filtro from './filtro';
import helpers from '@common/utils/helpers';
import RegistroOcorrenciaModal from './components/modais/RegistroOcorrenciaModal.vue';
import DuplicarInstrumentoModal from './components/modais/DuplicarInstrumentoModal.vue';
import MovimentacaoModal from './components/modais/MovimentacaoModal.vue';
import TecnicoResponsavelModal from './components/modais/TecnicoResponsavelModal.vue';
import DefineColunasModalPrimeVue from '@components/tabelas/DefineColunasModalPrimeVue.vue';
import ModalServicosPecas from './components/modais/ModalServicosPecas.vue';
import get from 'lodash/get';
export default {
  components: {
    CabecalhoPagina,
    Filtro,
    MovimentacaoModal,
    RegistroOcorrenciaModal,
    DuplicarInstrumentoModal,
    TecnicoResponsavelModal,
    DefineColunasModalPrimeVue,
    ModalServicosPecas,
  },
  data() {
    return {
      expandedRowGroups: null,
      mostrarDropDown: true,
      codigoTag: null,
      idInstrumento: null,
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'codigoTag',
            text: this.$t('modulos.instrumento.tabela.codigo'),
            sortable: true,
            styles: { 'max-width': '10%', 'word-break': 'break-all' },
          },
          {
            value: 'tipoInstrumento.nome',
            text: this.$t('modulos.instrumento.tabela.tipo'),
            sortable: true,
            width: '250',
          },
          {
            value: 'marca.nome',
            text: this.$t('modulos.instrumento.tabela.marca'),
            sortable: true,
          },
          {
            value: 'numeroSerie',
            text: this.$t('modulos.instrumento.tabela.numero_serie'),
            sortable: true,
          },
          {
            value: 'flagRevisadoPelaUnidadeNegocioMantenedora',
            text: this.$t('modulos.instrumento.tabela.revisado_conta'),
            sortable: false,
            formatter: (v) => {
              return helpers.BoleanoEnum.find((el) => el.value === v).text;
            },
          },
          {
            value: 'modeloInstrumento.nome',
            text: this.$t('modulos.instrumento.tabela.modelo'),
            sortable: true,
          },
          {
            value: 'flagPadrao',
            text: this.$t('modulos.instrumento.tabela.padrao'),
            sortable: true,
            styles: { 'max-width': '7%', 'justify-content': 'center' },
            formatter: (v) => {
              if (v) {
                return this.$t('enums.boleano.sim');
              } else {
                return this.$t('enums.boleano.nao');
              }
            },
          },
          {
            value: 'flagAtivo',
            text: this.$t('modulos.instrumento.tabela.ativo'),
            sortable: true,
            styles: { 'max-width': '7%', 'justify-content': 'center' },
            formatter: (v) => {
              return helpers.BoleanoEnum.find((el) => el.value === v).text;
            },
          },
          {
            value: 'faixa',
            text: this.$t('modulos.instrumento.tabela.faixas'),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      novasColunas: [],
      colunasSelecionadas: [],
      colunasKey: 0,
      itensPorpagina: 10,
      passo: 0,
      paginaAtual: 1,
      numeroItens: 1,
      filtroGlobal: null,
      watchs: {
        paginaAtual: null,
        itensPorpagina: null,
      },
      campo: '',
      ordem: 1
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados != 0;
    },
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'Instrumento', 'Listar');
    this.watchs.paginaAtual && this.watchs.paginaAtual();
    this.watchs.itensPorpagina && this.watchs.itensPorpagina();
    const filtros = this.buscarStateFiltros();
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.filtroGlobal = this.filtroAplicado.filter;
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
    this.paginaAtual = (filtros?.paginaAtual - 1) * this.tabela.porPagina || 0;
    this.itensPorpagina = filtros?.porPagina ?? 10;
    this.colunasSelecionadas = filtros?.colunasSelecionadas ?? [];
  },
  mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.instrumento.titulos.listagem')
    );
    this.listarRegistros();
    this.tabela.colunas?.forEach((coluna) => {
      coluna.field = coluna.value;
      coluna.header = coluna.text;
    });
    this.colunasSelecionadas = this.colunasSelecionadas.length
      ? this.colunasSelecionadas
      : this.tabela.colunas;
    this.setarWatchs();
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    abrirInstrumentoHistoricoServicosPecas: function (item) {
      this.$refs['modal-historico-servicos-pecas'].abrirModal(item);
    },
    abrirGraficoTendecia: function (instrumento) {
      this.$router.push({
        name: 'instrumento-grafico-tendencia',
        params: {
          instrumentoId: instrumento.id,
         },
        query: {
          retorno: 'instrumento',
        },
      });
    },
    filtrarGlobal() {
      this.filtroAplicado.filter = this.filtroGlobal ?? "";
      this.paginaAtual === 0 && this.listarRegistros();
      this.paginaAtual = 0;
    },
    setarWatchs() {
      this.watchs.paginaAtual = this.$watch('paginaAtual', () => {
        this.enviaDadosPaginacao();
      });
      this.watchs.itensPorpagina = this.$watch('itensPorpagina', () =>
        this.enviaDadosPaginacao()
      );
    },
    formatarValorColuna(coluna, data) {
      const valor = this.obterValorCampo(data, coluna.field);
      return Object.hasOwn(coluna, 'formatter')
        ? coluna.formatter(valor)
        : valor;
    },
    obterValorCampo: get,
    emitirEventoLinhaSelecionada() {
      this.$emit('linha-selecionada');
    },
    enviaDadosPaginacao: async function () {
      await this.$nextTick();
      this.passo = this.paginaAtual / this.itensPorpagina + 1;
      this.listarRegistros(this.passo, this.itensPorpagina);
    },
    abreModalDefineColunas() {
      this.$refs['modal-define-colunas'].abrirModal();
    },
    refazColunasTabela(colunas) {
      if (colunas.length) {
        this.novasColunas = [];
        this.tabela.colunas.forEach((col) =>
          colunas.forEach((novaColuna) => {
            if (col.header == novaColuna.coluna) {
              this.novasColunas.push(col);
            }
          })
        );
        this.aoSelecionarColuna(this.novasColunas);
      }
    },
    aoSelecionarColuna(value) {
      this.colunasSelecionadas = this.tabela.colunas.filter((col) =>
        value.includes(col)
      );
      ++this.colunasKey;
    },
    redirecionarRevisarInstrumento(item) {
      this.$router.push({
        name: 'instrumento-editar',
        params: { id: item.id, revisar: true },
      });
    },
    redirecionaRegistroManutencaoTrocaPeca(item) {
      this.$router.push({
        name: 'registro-manutencao-troca-pecas-instrumento',
        params: { id: item.id, codigo: item.codigoTag },
      });
    },
    abrirModalTecnicoResponsavel(item) {
      this.$refs['modal-tecnico-responsavel'].abrirModal(item);
    },
    abrirModalRegistroOcorrencia(item) {
      this.codigoTag = item.codigoTag;
      this.idInstrumento = item.id;
      this.$refs['modal-registro-ocorrencia'].abrirModal(item);
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.paginaAtual === 0 && this.listarRegistros();
      this.paginaAtual = 0;
    },

    listarRegistros: function (
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      this.salvarStateFiltros({
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
        colunasSelecionadas: this.colunasSelecionadas,
      });
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        sort: this.colunaOrdenada,
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      InstrumentoService.listar(parametros)
        .then((res) => {
          this.tabela.selecionados = [];
          this.tabela.dados = res.data.items;
          this.tabela.dados.map((item) => {
            item.numeroItens = 0;
          });

          let tabela = this.tabela.dados;
          tabela.forEach((itemTabela) => {
            this.tabela.dados.map((item) => {
              if (
                itemTabela.participanteCliente.nome ==
                item.participanteCliente.nome
              ) {
                item.numeroItens++;
              }
            });
          });
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;

          this.expandTabela();
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    expandTabela() {
      this.expandedRowGroups = [];
      this.tabela.dados.filter((p) => {
        this.expandedRowGroups.push(p.participanteCliente.nome);
      });
    },
    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        let promises = [];
        this.tabela.selecionados.forEach((item) => {
          promises.push(InstrumentoService.excluir(item.id));
        });
        Promise.all(promises)
          .then(() => {
            this.listarRegistros(
              this.tabela.paginaAtual,
              this.tabela.porPagina
            );
            this.toastSucesso(this.$t(`modulos.instrumento.exclusao_sucesso`));
            this.tabela.selecionados = [];
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    abrirNovo: function () {
      this.$router.push({ name: 'instrumento-novo' });
    },
    abrirEditar: function (item) {
      this.$router.push({
        name: 'instrumento-editar',
        params: { id: item.id },
      });
    },
    abrirPontosCalibracao: function (item) {
      this.$router.push({
        name: 'instrumento-pontos',
        params: { id: item.id },
      });
    },
    abrirModalMovimentacao: async function (item, tipoMovimento) {
      await this.listarRegistros();
      await this.$refs['modal-movimentacao'].abrirModal(item, tipoMovimento);
    },
    abrirModalDuplicarInstrumento(item) {
      this.$refs['modal-duplicar-instrumento'].abrirModal(item);
    },
    redirecionarVerificacaoIntermediaria(item) {
      this.$router.push({
        name: 'verificacao-intermediaria-instrumento',
        params: { id: item.id, codigo: item.codigoTag },
      });
    },
    statusInstrumento: function (item, status) {
      let form = {
        id: item.id,
        flagAtivo: status,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      InstrumentoService.alterarStatusInstrumento(form)
        .then(() => {
          this.listarRegistros();
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    alterarDropDown: function () {
      this.mostrarDropDown = false;
      setTimeout(() => {
        this.mostrarDropDown = true;
      }, 300);
    },
    exibirFaixa: function (item) {
      return item.quantidadeFaixas > 1
        ? item.quantidadeFaixas.toString().concat(' faixas')
        : item.faixa?.descricao;
    },
    buscarStateFiltros() {
      return this.$store.getters['Filtros/filtros'][this.$route.name];
    },
    salvarStateFiltros(dados) {
      this.$store.dispatch('Filtros/adicionarFiltro', {
        rota: this.$route.name,
        dados,
      });
    },
    abrirCertificados: function (item) {
      this.$router.push({
        name: 'instrumento-certificados',
        params: { id: item.id },
      });
    },
    abrirInstrumentoHistorico: function (item) {
      this.$router.push({
        name: 'instrumento-historico',
        params: { id: item.id },
      });
    },
    atualizarSentidoOrdenacao(event) {
      this.ordem = event == 1 ? '' : '-';
      this.novaOrdenacao()
    },
    atualizarOrdenacaoCampo(event) {
      this.campo = event;
    },
    novaOrdenacao() {
      let campoOrdenado = "";
      if (this.campo.includes('.')) campoOrdenado = helpers.formatarColunasComPonto(this.campo);
      else campoOrdenado = helpers.primeiraLetraMaiuscula(this.campo);
      this.colunaOrdenada = this.ordem + campoOrdenado;
      this.listarRegistros()
    }
  },
};
</script>
<style lang="scss">
.p-rowgroup-header {
  top: 0 !important;
}
.tabela-prime-vue-inherit {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}
:deep(.p-paginator .p-paginator-page-input .p-inputtext) {
  max-width: 4.7rem;
  height: 37px;
  padding-top: 12px;
}
</style>
