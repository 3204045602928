export class RegistroOcorrenciaModel {
  constructor({
    id,
    instrumentoId,
    instrumento,
    ocorrenciaPadraoId,
    ocorrenciaPadrao,
    colaboradorResponsavelId,
    colaboradorResponsavel,
    data = new Date(),
    observacao,
  }) {
    this.id = id;
    this.instrumentoId = instrumentoId;
    this.instrumento = instrumento;
    this.ocorrenciaPadraoId = ocorrenciaPadraoId;
    this.ocorrenciaPadrao = ocorrenciaPadrao;
    this.colaboradorResponsavelId = colaboradorResponsavelId;
    this.colaboradorResponsavel = colaboradorResponsavel;
    this.data = data;
    this.observacao = observacao;
  }
}
