import { BasicoApi } from '@common/api';
const MovimentacaoOutroSetorService = {
  listar: function (parametros) {
    return BasicoApi.get()('/movimentacaooutrosetor', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/movimentacaooutrosetor/${id}`);
  },
  salvar: function (form) {
    if (form.id)
      return BasicoApi.put()(`/movimentacaooutrosetor/${form.id}`, form);
    return BasicoApi.post()('/movimentacaooutrosetor', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/movimentacaooutrosetor/${id}`);
  },
  salvarDevolucao: function (form) {
    return BasicoApi.put()(`/movimentacaooutrosetor/${form.id}/devolucao`,form);
  },
};

export default MovimentacaoOutroSetorService;
