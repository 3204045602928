import _ from 'lodash';
import { DropdownModel } from '@/common/models/geral/DropdownModel';
export class InstrumentoResponsavelTecnicoModel {
  constructor({
    instrumento,
    colaboradorOrigem,
    colaboradorDestino,
    dataRegistroAlteracao,
    ocorrenciaPadrao,
    observacao,
    usuario,
  }) {
    this.instrumentoId = instrumento?.id;
    if (colaboradorOrigem)
      this.colaboradorOrigemId = new DropdownModel(colaboradorOrigem);
    if (colaboradorDestino)
      this.colaboradorDestinoId = new DropdownModel(colaboradorDestino);
    this.dataRegistroAlteracao = dataRegistroAlteracao;
    if (ocorrenciaPadrao)
      this.ocorrenciaPadraoId = new DropdownModel(ocorrenciaPadrao);
    this.usuarioId = usuario;
    this.observacao = observacao;
  }
  get request() {
    let retorno = _.cloneDeep(this);

    retorno.colaboradorOrigemId = this.colaboradorOrigemId?.value;
    retorno.colaboradorDestinoId = this.colaboradorDestinoId?.value;
    retorno.ocorrenciaPadraoId = this.ocorrencia?.value;

    return retorno;
  }
}
