<template lang="">
  <modal-padrao
    ref="modal-movimentacao"
    max-width="70%"
    :titulo="tituloModal"
    :titulo-cancelar="$t('geral.botoes.cancelar')"
    :titulo-ok="$t('geral.botoes.confirmar')"
    :mostra-botao-salvar="false"
    :mostrar-botao-cancelar="indiceAba == 0"
    @cancelar="eventoBotaoVoltar"
  >
    <v-tabs
      v-model="indiceAba"
      class="abas-padrao"
      background-color="primary"
      color="primary"
      v-if="false"
    >
      <v-tab>
        {{ abaHistorico }}
      </v-tab>
      <v-tab :disabled="disabledAbaDetalhe">
        {{ abaDetalhe }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="indiceAba">
      <v-tab-item>
        <botao-padrao class="mb-2" align="end" @click="abrirFormMovimentacoes">
          {{ botaoAdd }}
        </botao-padrao>
        <tabela-padrao
          v-model="tabela.selecionados"
          :dados="tabela.dados"
          class="mt-2"
          :colunas="colunas"
          :por-pagina="-1"
          sem-paginacao
          selecionar-apenas-um
        >
          <template v-slot:item.dataDevolucao="{ item }">
            <div v-if="tipoMovimento !== 'Definitivo'">
              <span v-if="item.item.dataDevolucao">
                {{ formatarDataDevolucao(item.item.dataDevolucao) }}
              </span>
              <v-icon
                v-else
                class="d-flex align-center justify-center"
                color="#F79009"
              >
                $alertCircleOutline
              </v-icon>
            </div>
          </template>
        </tabela-padrao>
      </v-tab-item>
      <v-tab-item>
        <movimentacao-form-modal
          ref="formModalMovimentacao"
          :dados-instrumento="instrumento"
          :tipo-movimento="tipoMovimento"
          @reload-lista-movimento="listarMovimentacao"
          @cancelar-form="cancelarForm"
        />
      </v-tab-item>
    </v-tabs-items>
  </modal-padrao>
</template>
<script>
import MovimentacaoOutroSetorService from '@common/services/cadastros/MovimentacaoOutroSetorService';
import helpers from '@/common/utils/helpers';
import MovimentacaoFormModal from './MovimentacaoFormModal.vue';
import _ from 'lodash';
export default {
  components: { MovimentacaoFormModal },
  data() {
    return {
      setorAtual: null,
      tipoMovimento: null,
      mostrarBotaoCancelar: false,
      indiceAba: 0,
      instrumento: null,
      tabela: {
        selecionados: [],
        dados: [],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
        setorAtual: null,
      },
      disabledAbaDetalhe: true,
      abaHistorico: null,
      abaDetalhe: null,
      botaoAdd: null,
    };
  },
  computed: {
    tituloModal: function () {
      if (this.tipoMovimento === 'Definitivo')
        return this.$t(
          'modulos.instrumento.modal_movimentacao.titulo_movimentacao'
        );
      return this.$t(
        'modulos.instrumento.modal_movimentacao.titulo_emprestimo'
      );
    },
    disabledAddoMovimento: function () {
      return (
        this.tipoMovimento === 'Emprestimo' && this.form.dataDevolucao === null
      );
    },
    colunas: function () {
      let headers = [
        {
          value: 'instrumento.codigo',
          text: this.$t(
            'modulos.instrumento.modal_movimentacao.tabela.instrumento'
          ),
          sortable: false,
        },
        {
          value: 'setorOrigem.nome',
          text: this.$t(
            'modulos.instrumento.modal_movimentacao.tabela.setor_origem'
          ),
          sortable: false,
        },
        {
          value: 'setorDestino.nome',
          text: this.$t(
            'modulos.instrumento.modal_movimentacao.tabela.setor_destino'
          ),
          sortable: false,
        },
        {
          value: 'dataMovimento',
          text: this.$t(
            'modulos.instrumento.modal_movimentacao.tabela.data_movimentacao'
          ),
          sortable: false,
          formatter: (v) => {
            if (v) return helpers.formatarDataBr(v, false);
          },
        },
      ];

      if (this.tipoMovimento === 'Emprestimo') {
        headers.push({
          value: 'dataDevolucao',
          text: this.$t(
            'modulos.instrumento.modal_movimentacao.tabela.data_retorno'
          ),
          sortable: false,
          formatter: (v) => {
            if (v) return helpers.formatarDataBr(v, false);
          },
        });
        return headers;
      }
      return headers;
    },
  },
  watch: {
    'tabela.selecionados': {
      handler() {
        this.disabledAbaDetalhe = !this.tabela.selecionados.length;
      },
    },
    indiceAba: {
      handler() {
        if (this.indiceAba == 1 && this.tabela.selecionados.length) {
          const { id } = this.tabela.selecionados[0];
          this.$nextTick()
            .then(() => this.$nextTick())
            .then(() =>
              this.$refs.formModalMovimentacao.buscarMovimentacao(id)
            );
        }
        this.tabela.selecionados = [];
      },
    },
    'form.setorDestinoInstrumentoId': {
      handler() {
        this.disabledSetorDestino = this.form.id && this.disabledSetorDestino;
      },
    },
  },
  methods: {
    eventoBotaoVoltar() {
      if (this.indiceAba) this.indiceAba = 0;
      else this.$refs['modal-movimentacao'].fecharModal();
    },
    abrirModal: function (instrumento, tipoMovimento) {
      this.indiceAba = 0;
      this.instrumento = _.cloneDeep(instrumento);
      this.tipoMovimento = tipoMovimento;
      this.definindoTextoPorTipoMovimento(this.tipoMovimento);
      this.listarMovimentacao();
      this.$refs['modal-movimentacao'].abrirModal();
    },
    listarMovimentacao: function (
      id = this.instrumento.id,
      paginaAtual = 1,
      porPagina = 10,
      tipoMovimento = this.tipoMovimento
    ) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        instrumentoId: id,
        tipoMovimento: tipoMovimento,
      };
      MovimentacaoOutroSetorService.listar(parametros).then((res) => {
        this.tabela.dados = res.data.items;
        this.setorAtual = this.tabela.dados[0]?.setorAtual;
        this.tabela.quantidadeItens = res.data.totalItems;
        this.tabela.quantidadePaginas = res.data.totalPages;
        this.tabela.paginaAtual = paginaAtual;
        this.tabela.porPagina = porPagina;
        this.tabela.selecionados = [];
      });
    },
    abrirFormMovimentacoes: function () {
      this.indiceAba = 1;
      this.tabela.selecionados = [];
      this.instrumento = {
        ...this.instrumento,
        setorAtual: this.setorAtual,
      };
      this.$nextTick()
        .then(() => {
          return this.$nextTick();
        })
        .then(() => this.$refs.formModalMovimentacao.iniciarForm());
    },
    salvar: function () {
      if (!this.form.dataDevolucao) return this.salvarMovimentacao();
      return this.salvarDevolucao();
    },

    cancelarForm: function () {
      this.indiceAba = 0;
      this.tabela.selecionados = [];
    },
    definindoTextoPorTipoMovimento: function (tipoMovimento) {
      if (tipoMovimento === 'Emprestimo') {
        this.abaHistorico = this.$t(
          'modulos.instrumento.modal_movimentacao.aba_historico_emprestimo'
        );
        this.abaDetalhe = this.$t(
          'modulos.instrumento.modal_movimentacao.aba_detalhe_emprestimo'
        );
        this.botaoAdd = this.$t(
          'modulos.instrumento.modal_movimentacao.add_emprestimo'
        );
      } else {
        this.abaHistorico = this.$t(
          'modulos.instrumento.modal_movimentacao.aba_historico_movimentacao'
        );
        this.abaDetalhe = this.$t(
          'modulos.instrumento.modal_movimentacao.aba_detalhe_movimentacao'
        );
        this.botaoAdd = this.$t(
          'modulos.instrumento.modal_movimentacao.add_movimentacao'
        );
      }
    },
    formatarDataDevolucao: function (dataDevolucao) {
      return helpers.formatarDataBr(dataDevolucao, false);
    },
  },
};
</script>
