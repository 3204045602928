<template lang="">
  <modal-padrao
    ref="modal-tecnico-responsavel"
    class="modal-tecnico-responsavel"
    max-width="80%"
    :titulo="$t('modulos.instrumento.botoes.alterar_tecnico_responsavel')"
    :titulo-cancelar="$t('geral.botoes.cancelar')"
    :titulo-ok="$t('geral.botoes.confirmar')"
    :mostra-botao-salvar="botaoSalvar"
    prevent-default
    @ok="salvar"
    @cancelar="eventoBotaoVoltar"
  >
    <v-tabs
      v-model="indiceAba"
      class="abas-padrao"
      background-color="primary"
      color="primary"
      v-if="false"
    >
      <v-tab class="v-tab-tecnico-responsavel">
        {{
          $t(
            'modulos.instrumento.modal_movimentacao.aba_alteracoes_tecnicos_responsaveis'
          )
        }}
      </v-tab>
      <v-tab disabled class="v-tab-tecnico-responsavel">
        {{ $t('modulos.instrumento.modal_movimentacao.aba_detalhe_alteracao') }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="indiceAba">
      <v-tab-item>
        <div class="d-flex justify-space-between">
          <div v-if="!tabela.selecionados.length" />
          <botao-padrao
            v-if="tabela.selecionados.length"
            class=""
            color="secondary"
            outlined
            @click="abrirFormMovimentacoes"
          >
            {{ $t('geral.botoes.visualizar') }}
          </botao-padrao>
          <botao-padrao
            class="mb-2"
            align="end"
            @click.prevent="abrirFormMovimentacoes(false)"
          >
            {{ $t('modulos.instrumento.botoes.alterar_responsavel') }}
          </botao-padrao>
        </div>
        <tabela-padrao
          v-model="tabela.selecionados"
          :dados="tabela.dados"
          class="mt-2"
          :colunas="tabela.colunas"
          :por-pagina="tabela.porPagina"
          :quantidade-paginas="tabela.quantidadePaginas"
          selecionar-apenas-um
          @paginar="listarRegistros"
        />
      </v-tab-item>
      <v-tab-item>
        <TecnicoResponsavelFormModal
          ref="form-modal"
          :form="form"
          :participanteClienteId="participanteClienteId"
        />
      </v-tab-item>
    </v-tabs-items>
  </modal-padrao>
</template>
<script>
import helpers from '@/common/utils/helpers';
import TecnicoResponsavelFormModal from './TecnicoResponsavelFormModal.vue';
import InstrumentoResponsavelTecnicoService from '@/common/services/cadastros/InstrumentoResponsavelTecnicoService';
import { InstrumentoResponsavelTecnicoModel } from '@/common/models/cadastros/InstrumentoResponsavelTecnicoModel';

export default {
  components: { TecnicoResponsavelFormModal },
  data() {
    return {
      instrumento: {},
      form: new InstrumentoResponsavelTecnicoModel({}),
      indiceAba: 1,
      participanteClienteId: null,
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
            sortable: false,
            width: 10,
          },
          {
            value: 'colaboradorOrigem.nome',
            text: this.$t(
              'modulos.instrumento.modal_movimentacao.form.colaborador_atual'
            ),
            sortable: false,
            width: 200,
          },
          {
            value: 'colaboradorDestino.nome',
            text: this.$t(
              'modulos.instrumento.modal_movimentacao.form.colaborador_destino'
            ),
            sortable: false,
            width: 250,
          },
          {
            value: 'dataAlteracao',
            text: this.$t(
              'modulos.instrumento.modal_movimentacao.form.data_alteracao'
            ),
            sortable: false,
            width: 200,
            formatter: (v) => helpers.formatarDataBr(v, false),
          },
          {
            value: 'updatedAt',
            text: this.$t(
              'modulos.instrumento.modal_movimentacao.form.data_registro'
            ),
            sortable: false,
            formatter: (v) => helpers.formatarDataBr(v, false),
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
        setorAtual: null,
      },
      disabledAbaDetalhe: false,
      abaHistorico: null,
      abaDetalhe: null,
      botaoAdd: null,
    };
  },
  computed: {
    botaoSalvar() {
      return !!this.indiceAba && !this.tabela.selecionados.length;
    },
  },
  methods: {
    eventoBotaoVoltar(e) {
      e.preventDefault();
      if (this.indiceAba) this.indiceAba = 0;
      else this.$refs['modal-tecnico-responsavel'].fecharModal();
    },
    listarRegistros: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        instrumentoId: this.instrumento.id,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      InstrumentoResponsavelTecnicoService.listar(parametros)
        .then((res) => {
          this.tabela.selecionados = [];
          this.tabela.dados = res.data.items;
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    abrirModal: function (instrumento) {
      this.form = new InstrumentoResponsavelTecnicoModel({});
      this.instrumento = instrumento;
      this.participanteClienteId = instrumento.participanteCliente?.id;
      this.indiceAba = 0;
      this.listarRegistros();
      this.$refs['modal-tecnico-responsavel'].abrirModal();
    },
    exibirDetalhes: async function () {
      const item = this.tabela.selecionados[0];
      await InstrumentoResponsavelTecnicoService.buscar(item.id).then((res) => {
        this.form = new InstrumentoResponsavelTecnicoModel(res.data);
        this.usuario = res.data.usuario;
      });
      this.indiceAba = 1;
      this.$nextTick()
        .then(() => {
          return this.$nextTick();
        })
        .then(() =>
          this.$refs['form-modal'].iniciarForm(this.instrumento, null, item)
        );
    },
    abrirFormMovimentacoes: function (visualizar = false) {
      if (visualizar) {
        this.exibirDetalhes();
        return;
      }
      this.indiceAba = 1;
      this.tabela.selecionados = [];
      this.$nextTick()
        .then(() => {
          return this.$nextTick();
        })
        .then(() =>
          this.$refs['form-modal'].iniciarForm(
            this.instrumento,
            this.tabela.dados[0]
          )
        );
    },
    validarFormulario() {
      const valido = this.$refs['form-modal'].$refs.form.validate();
      if (!valido) return false;

      if (
        this.form.colaboradorOrigemId?.value ==
        this.form.colaboradorDestinoId?.value
      ) {
        this.toastAlerta(
          this.$t(
            'modulos.instrumento.validacoes.colaborador_atual_diferente_destino'
          )
        );
        return false;
      }

      return true;
    },
    salvar() {
      if (!this.validarFormulario()) return;
      InstrumentoResponsavelTecnicoService.salvar(this.form.request).then(
        () => {
          this.toastSucesso(
            this.$t('modulos.instrumento.responsavel_tecnico_sucesso')
          );
          this.indiceAba = 0;
          this.listarRegistros();
        }
      );
    },
  },
};
</script>
<style>
.v-tab-tecnico-responsavel {
  letter-spacing: 0;
}
</style>
