<template>
  <div class="row">
    <input-text
      v-model="filtro.participanteNome"
      class="col-12 col-md-6"
      :label="$t('modulos.instrumento.filtro.cliente')"
      em-filtro
      retornar-objeto
    />
    <input-text
      v-model="filtro.codigoTag"
      class="col-12 col-md-6"
      :label="$t('modulos.instrumento.filtro.codigo')"
      em-filtro
    />
    <input-text
      v-model="filtro.nomeTipoInstrumento"
      class="col-12 col-md-6"
      :label="$t('modulos.instrumento.filtro.tipo')"
      em-filtro
    />
    <input-text
      v-model="filtro.numeroSerie"
      class="col-12 col-md-6"
      :label="$t('modulos.instrumento.filtro.numero_serie')"
      em-filtro
    />
    <input-text
      v-model="filtro.nomeSetor"
      class="col-12 col-md-6"
      :label="$t('modulos.instrumento.filtro.setor')"
      em-filtro
    />
    <input-select
      v-model="filtro.flagPadrao"
      class="col-12 col-md-6"
      :label="$t('modulos.instrumento.filtro.flag_padrao')"
      :options="opcoes.boleano"
      em-filtro
      retornar-objeto
    />
    <input-select
      v-model="filtro.flagAtivo"
      class="col-12 col-md-6"
      :label="$t('modulos.instrumento.filtro.flag_ativo')"
      :options="opcoes.boleano"
      em-filtro
      retornar-objeto
    />
    <input-select
      v-model="filtro.flagRevisadoPelaUnidadeNegocioMantenedora"
      class="col-12 col-md-6"
      :label="$t('modulos.instrumento.filtro.revisado_conta')"
      :options="opcoes.boleano"
      em-filtro
      retornar-objeto
    />
  </div>
</template>
<script>
import helpers from '@common/utils/helpers'
export default {
  props: {
    filtro: {},
  },
  data() {
    return {
      opcoes:{
        boleano: helpers.BoleanoEnum,
      },
    };
  },
};
</script>
