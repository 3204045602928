<template>
  <modal-padrao
    ref="modal-duplicar"
    max-width="60%"
    :titulo="$t('modulos.instrumento.botoes.duplicar_instrumento')"
    :titulo-ok="$t('geral.botoes.confirmar')"
    :titulo-cancelar="$t('geral.botoes.voltar')"
    @ok="confirmacaoSalvar"
  >
    <v-form
      ref="form"
      class="row"
    >
      <input-text
        :value="codigoInstrumentoPai"
        class="col-12 col-md-4"
        :label="$t('modulos.instrumento.formulario.codigo')"
        disabled
      />
      <input-text
        :value="tipoInstrumentoPai"
        class="col-12 col-md-8"
        :label="$t('modulos.instrumento.formulario.tipo_de_instrumento')"
        disabled
      />
      <input-text
        v-model="form.codigoTag"
        class="col-12 col-md-12"
        :label="$t('modulos.instrumento.formulario.codigo_novo_instrumento')"
        obrigatorio
        :regras-personalizadas="verificarCodigo()"
      />
      <input-checkbox
        v-model="form.flagCamposAdicionais"
        class="col-12 col-md-12"
        :label="$t('modulos.instrumento.formulario.copiar_campos_adicionais')"
      />
    </v-form>
  </modal-padrao>
</template>
<script>
import InstrumentoService from '@common/services/cadastros/InstrumentoService.js'
import { InstrumentoDuplicarModel } from '@common/models/cadastros/InstrumentoDuplicarModel';
export default {
  data() {
    return {
      form: {},
      codigoInstrumentoPai: "",
      tipoInstrumentoPai: "",
      idInstrumentoPai: "",
      participante: {},
    }
  },
  methods:{
    verificarCodigo: function(){
      if (!this.form.codigoTag) return [];

      const regraCodigoIgual = (v) =>{
        if(v.toUpperCase() === this.codigoInstrumentoPai.toUpperCase()) return this.$t('modulos.instrumento.erros.codigo_duplicado')
      
        return true
      }

      return [regraCodigoIgual]

    },
    abrirModal(instrumento){
      this.codigoInstrumentoPai = instrumento?.codigoTag;
      this.tipoInstrumentoPai = instrumento?.tipoInstrumento?.nome;
      this.idInstrumentoPai = instrumento?.id;
      this.participante = instrumento?.participanteCliente;
      this.iniciarForm();
      this.$refs['modal-duplicar'].abrirModal();
      this.$nextTick(()=>{
        this.$refs.form.resetValidation()
      })
    },
    iniciarForm(){
      this.form = new InstrumentoDuplicarModel({})
    },
    confirmacaoSalvar(){
      if(!this.$refs.form.validate()) return;
      this.$store.dispatch('Layout/iniciarCarregamento');

      this.form.participanteId = this.participante?.id;
      InstrumentoService.duplicar(this.idInstrumentoPai, this.form)
        .then(() => {
          this.toastSucesso(this.$t('modulos.instrumento.duplicar_sucesso'));
          this.$emit('duplicarInstrumento')
          this.$refs['modal-duplicar'].fecharModal();
        }).catch(() => {
          this.toastErro(this.$t('modulos.instrumento.erros.codigoTagJaCadastradoParaEsseParticipante'))
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        })
    }
  },
}
</script>
