<template lang="">
  <div>
    <v-form ref="form">
      <div class="row mt-3">
        <input-text
          v-model="form.instrumentoCodigo"
          :class="classes"
          :label="$t('modulos.instrumento.modal_movimentacao.form.instrumento')"
          disabled
          obrigatorio
        />
        <input-text
          v-model="form.setorOrigemNome"
          :class="classes"
          :label="
            $t('modulos.instrumento.modal_movimentacao.form.setor_origem')
          "
          disabled
        />
        <input-setor
          :key="keyAtualizaSetor"
          v-model="form.setorDestinoInstrumento"
          :class="classes"
          :label="
            $t('modulos.instrumento.modal_movimentacao.form.setor_destino')
          "
          :ocultar-setor="form.setor"
          :multiplos="false"
          solo
          :disabled="disabledSetorDestino"
          obrigatorio
        />
        <input-text
          v-model="form.usuario"
          :class="classes"
          :label="$t('modulos.instrumento.modal_movimentacao.form.usuario')"
          disabled
          obrigatorio
        />
        <input-date
          v-model="form.dataMovimento"
          :class="classes"
          :label="
            $t('modulos.instrumento.modal_movimentacao.form.data_movimento')
          "
          disabled
          obrigatorio
        />
        <input-ocorrencia-instrumento
          v-model="form.ocorrencia"
          :class="classes"
          :label="$t('modulos.instrumento.modal_movimentacao.form.ocorrencia')"
          :multiplos="false"
          solo
          selecionar-apenas-um
          :disabled="disabledOcorrencia"
          :instrumentoId="form.instrumentoId"
        />
        <input-usuarios
          v-if="tipoMovimento === 'Emprestimo'"
          v-model="form.usuarioDevolucao"
          :class="classes"
          :label="
            $t('modulos.instrumento.modal_movimentacao.form.usuario_retorno')
          "
          :multiplos="false"
          disabled
          solo
        />
        <input-date
          v-if="tipoMovimento === 'Emprestimo'"
          :key="keyAtualiza"
          v-model="form.dataDevolucao"
          :class="classes"
          :label="
            $t('modulos.instrumento.modal_movimentacao.form.data_retorno')
          "
          :disabled="disabledDevolucao"
        />
        <input-textarea
          v-model="form.observacao"
          class="col-12 col-md-12"
          :label="$t('modulos.instrumento.modal_movimentacao.form.observacoes')"
          :disabled="disabledObservacao"
        />
      </div>
    </v-form>
    <div class="d-flex align-end justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="my-3 mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.voltar') }}
      </botao-padrao>
      <botao-padrao class="my-3" :disabled="disabledConfirmar" @click="salvar">
        {{ $t('geral.botoes.confirmar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import MovimentacaoOutroSetorService from '@common/services/cadastros/MovimentacaoOutroSetorService';
import InputSetor from '@/components/inputs/especificos/InputSetor';
import InputOcorrenciaInstrumento from '@/components/inputs/especificos/InputOcorrenciaInstrumento';
import InputUsuarios from '@/components/inputs/especificos/InputUsuarios';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import { MovimentacaoOutroSetorFormMovimentacaoModel } from '@common/models/cadastros/MovimentacaoOutroSetorFormMovimentacaoModel';
import store from '@/store';
export default {
  components: { InputSetor, InputUsuarios, InputOcorrenciaInstrumento },
  props: {
    dadosInstrumento: {},
    tipoMovimento: { type: String, default: '' },
  },
  data() {
    return {
      valido: true,
      keyAtualiza: 1,
      keyAtualizaSetor: 2,
      mostrarBotaoSalvar: false,
      form: new MovimentacaoOutroSetorFormMovimentacaoModel({}),
      instrumento: null,
      abaHistorico: null,
      abaDetalhe: null,
      botaoAdd: null,
      disabledAbaDetalhe: true,
      disabledDevolucao: true,
      disabledSetorDestino: false,
      disabledOcorrencia: false,
      disabledObservacao: false,
      disabledConfirmar: false,
    };
  },
  computed: {
    classes: function () {
      return this.tipoMovimento === 'Emprestimo'
        ? 'col-6 col-md-3'
        : 'col-6 col-md-4';
    },
  },
  watch: {
    'form.setorDestinoInstrumento': {
      handler() {
        this.disabledSetorDestino = this.form.id && this.disabledSetorDestino;
      },
    },
  },
  methods: {
    iniciarForm: function () {
      this.form.id = null;
      this.form.dataMovimento = new Date();
      this.form.instrumentoCodigo = this.dadosInstrumento.codigoTag;
      this.form.instrumentoId = this.dadosInstrumento.id;
      this.form.setorOrigemNome = this.setorOrigemNome();
      this.form.tipoMovimento = this.tipoMovimento;
      this.form.setorDestinoInstrumento = null;
      this.form.ocorrencia = null;
      this.form.dataDevolucao = null;
      this.form.usuarioDevolucao = null;
      this.form.observacao = null;
      this.form.setor = this.dadosInstrumento.setorAtual?.id;

      this.disabledSetorDestino = false;
      this.disabledDevolucao = true;
      this.disabledOcorrencia = false;
      this.disabledObservacao = false;
      this.disabledConfirmar = false;
      this.valido = true;
      this.keyAtualiza++;
      this.keyAtualizaSetor++;
    },
    salvar: function () {
      if (this.$refs.form) {
        this.valido = this.$refs.form.validate();
        if (this.form.dataDevolucao) {
          return this.salvarDevolucao();
        } else {
          return this.salvarMovimentacao();
        }
      }
    },
    salvarMovimentacao: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      MovimentacaoOutroSetorService.salvar(this.form.request)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.instrumento.modal_movimentacao.cadastro_sucesso`)
          );
          this.cancelar();
          this.$nextTick().then(() => {
            this.$emit('reload-lista-movimento');
          });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    salvarDevolucao: function () {
      this.form.tipoMovimento = 'Devolucao';
      this.$store.dispatch('Layout/iniciarCarregamento');
      MovimentacaoOutroSetorService.salvarDevolucao(this.form.request)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.instrumento.modal_movimentacao.cadastro_sucesso`)
          );
          this.cancelar();
          this.$nextTick()
            .then(() => this.$nextTick())
            .then(() => {
              this.$emit('reload-lista-movimento');
            });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarMovimentacao: function (id) {
      MovimentacaoOutroSetorService.buscar(id).then((res) => {
        this.form.id = res.data.id;
        this.form.instrumentoCodigo = res.data.instrumento.codigo;
        this.form.instrumentoId = res.data.instrumento?.id;
        this.form.setorOrigemNome = res.data.setorOrigem.nome;
        this.form.tipoMovimento = this.tipoMovimento;
        this.form.dataDevolucao = res.data.dataDevolucao;
        this.form.dataMovimento = res.data.dataMovimento;
        this.form.observacao = res.data.observacao;
        this.form.usuario = res.data.usuario.nome;
        if (res.data.setorDestino)
          this.form.setorDestinoInstrumento = new DropdownModel(
            res.data?.setorDestino
          );
        if (res.data.ocorrencia)
          this.form.ocorrencia = new DropdownModel(res.data.ocorrencia);
        this.form.usuarioDevolucao = !res.data.usuarioDevolucao
          ? store.getters['Autenticacao/usuario'].nome
          : new DropdownModel(res.data.usuarioDevolucao);
        this.disabledSetorDestino = true;
        this.disabledDevolucao = this.tipoMovimento != 'Emprestimo';
        this.disabledObservacao = false;
        if (res.data.setorDestino) this.disabledOcorrencia = true;

        if (this.form.dataDevolucao) {
          this.disabledOcorrencia = true;
          this.disabledDevolucao = true;
          this.disabledObservacao = true;
          this.disabledConfirmar = true;
        }
      });
    },
    cancelar: function () {
      this.$emit('cancelar-form');
      this.$refs.form.resetValidation();
    },
    setorOrigemNome: function () {
      if (!this.dadosInstrumento.setorAtual) {
        return (
          this.dadosInstrumento.setor?.nome ??
          this.$t(
            'modulos.instrumento.modal_movimentacao.form.instrumento_sem_setor'
          )
        );
      }
      return this.dadosInstrumento.setorAtual?.nome;
    },
  },
};
</script>
