<template>
  <v-form ref="form" class="row mt-2">
    <input-text
      :value="instrumento.codigoTag"
      class="col-12 col-md-3"
      :label="$t('modulos.instrumento.modal_movimentacao.form.instrumento')"
      disabled
    />
    <InputColaborador
      v-model="form.colaboradorOrigemId"
      class="col-12 col-md-3"
      :label="
        $t('modulos.instrumento.modal_movimentacao.form.colaborador_atual')
      "
      disabled
    />
    <InputColaborador
      v-model="form.colaboradorDestinoId"
      class="col-12 col-md-3"
      :label="
        $t('modulos.instrumento.modal_movimentacao.form.colaborador_destino')
      "
      :disabled="somenteVisualizacao"
      obrigatorio
      :colaboradorIdRemoverListagem="colaboradorIdRemoverListagem"
      :participanteClienteId="participanteClienteId"
    />
    <input-text
      :value="usuario.nome"
      class="col-12 col-md-3"
      :label="$t('modulos.instrumento.modal_movimentacao.form.usuario')"
      disabled
    />
    <input-date
      v-model="form.dataRegistroAlteracao"
      class="col-12 col-md-4"
      :label="$t('modulos.instrumento.modal_movimentacao.form.data_alteracao')"
      :disabled="somenteVisualizacao"
      obrigatorio
    />
    <InputOcorrencia
      v-model="form.ocorrenciaPadraoId"
      :multiplos="false"
      class="col-12 col-md-4"
      :label="$t('modulos.instrumento.modal_movimentacao.form.ocorrencia')"
      :disabled="somenteVisualizacao"
    />
    <input-date
      v-model="form.dataRetorno"
      class="col-12 col-md-4"
      :label="$t('modulos.instrumento.modal_movimentacao.form.data_retorno')"
      disabled
    />
    <input-textarea
      v-model="form.observacao"
      class="col-12 col-md-12"
      :label="$t('modulos.instrumento.modal_movimentacao.form.observacoes')"
      :disabled="somenteVisualizacao"
    />
  </v-form>
</template>
<script>
import InputColaborador from '@/components/inputs/especificos/InputColaborador';
import InputOcorrencia from '@/components/inputs/especificos/InputOcorrencia';
import { DropdownModel } from '@/common/models/geral/DropdownModel';

export default {
  components: { InputColaborador, InputOcorrencia },
  props: {
    form: {},
    participanteClienteId: { type: String, default: '' },
  },
  data() {
    return {
      opcoes: {},
      instrumento: {},
      usuario: {},
      somenteVisualizacao: false,
      inputColaboradorDesabilitado: false,
      colaboradorIdRemoverListagem: null,
    };
  },
  methods: {
    iniciarForm(instrumento, ultimoRegistro, visualizarItem) {
      this.colaboradorIdRemoverListagem = null;
      this.inputColaboradorDesabilitado = false;
      this.instrumento = instrumento;
      if (visualizarItem) {
        this.exibirDetalhesItem(visualizarItem);
        return;
      }
      this.somenteVisualizacao = false;
      if (ultimoRegistro) {
        this.inputColaboradorDesabilitado = true;
        this.form.colaboradorOrigemId = new DropdownModel(
          ultimoRegistro.colaboradorDestino
        );
        this.colaboradorIdRemoverListagem =
          this.form.colaboradorOrigemId?.value;
      }
      this.usuario = this.$store.getters['Autenticacao/usuario'];
      this.form.instrumentoId = instrumento?.id;
      this.form.usuarioId = this.usuario?.id;
      this.form.colaboradorDestinoId = null;
    },
    exibirDetalhesItem() {
      this.inputColaboradorDesabilitado = true;
      this.somenteVisualizacao = true;
    },
  },
};
</script>
>
