import { BasicoApi } from '@common/api';
const InstrumentoResponsavelTecnicoService = {
  listar: function (parametros) {
    return BasicoApi.get()('/instrumentoResponsavelTecnico', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/instrumentoResponsavelTecnico/${id}`);
  },
  salvar: function (form) {
    if (form.id)
      return BasicoApi.put()(`/instrumentoResponsavelTecnico/${form.id}`, form);
    return BasicoApi.post()('/instrumentoResponsavelTecnico', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/instrumentoResponsavelTecnico/${id}`);
  },
};

export default InstrumentoResponsavelTecnicoService;
