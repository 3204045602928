import _ from 'lodash';
import store from '@/store';
import { DropdownModel } from '../geral/DropdownModel';
export class MovimentacaoOutroSetorFormMovimentacaoModel {
  constructor({
    id,
    instrumentoId,
    tipoMovimento,
    dataMovimento,
    setorDestinoInstrumento,
    ocorrencia = null,
    observacao = null,
    usuario = store.getters['Autenticacao/usuario'].nome,
    instrumentoCodigo = null,
    setorOrigemNome,
    dataDevolucao,
  }) {
    this.id = id;
    this.instrumentoId = instrumentoId;
    this.tipoMovimento = tipoMovimento;
    this.dataMovimento = dataMovimento;
    if (setorDestinoInstrumento)
      this.setorDestinoInstrumento = new DropdownModel(setorDestinoInstrumento);
    this.ocorrencia = ocorrencia;
    this.observacao = observacao;
    this.usuario = usuario;
    this.instrumentoCodigo = instrumentoCodigo;
    this.setorOrigem = setorOrigemNome;
    this.dataDevolucao = dataDevolucao;
  }
  get request() {
    let retorno = _.cloneDeep(this);
    retorno.ocorrenciaId = this.ocorrencia?.value;
    retorno.setorDestinoInstrumentoId = this.setorDestinoInstrumento?.value;
    if(this.tipoMovimento == 'Devolucao') retorno.dataMovimento = this.dataDevolucao
    return retorno;
  }
}
