<template lang="">
  <modal-padrao
    ref="modal-registro-ocorrencia"
    max-width="90%"
    :titulo="
      $t('modulos.instrumento.botoes.registros_ocorrencias', {
        codigoInstrumento: codigoTag,
      })
    "
    :titulo-ok="$t('geral.botoes.salvar')"
    :titulo-cancelar="$t('geral.botoes.cancelar')"
    :mostrar-botoes="false"
    @cancelar="eventoBotaoVoltar"
  >
    <v-tabs
      v-if="false"
      v-model="indiceAba"
      class="abas-padrao"
      background-color="primary"
      color="primary"
    >
      <v-tab>
        {{ $t('modulos.instrumento.botoes.registro_ocorrencia') }}
      </v-tab>
      <v-tab disabled>
        {{ $t('modulos.instrumento.formulario.abas.detalhes_registro') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="indiceAba">
      <v-tab-item>
        <index
          :id-instrumento="idInstrumento"
          :atualiza-listagem="atualizaListagem"
          :instrumento-ocorrencia="permissoesIntrumentoOcorrencia"
          @troca-aba="trocaAbaNovoRegistro"
        />
      </v-tab-item>
      <v-tab-item>
        <formulario
          :id="idRegistro"
          ref="formulario-registro-ocorrencia"
          :id-instrumento="idInstrumento"
          :atualiza-formulario="atualizaFormulario"
          :participante-cliente-id="participanteClienteId"
          @fechar-modal="eventoBotaoVoltar"
        />
      </v-tab-item>
    </v-tabs-items>
    <v-divider v-if="indiceAba === 0" class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        v-if="indiceAba === 0"
        outlined
        color="secondary"
        class="mr-2"
        @click="eventoBotaoVoltar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
    </div>
  </modal-padrao>
</template>
<script>
import Index from '@views/modulos/cadastros/registro_ocorrencia';
import Formulario from '@views/modulos/cadastros/registro_ocorrencia/formulario';

export default {
  components: {
    Index,
    Formulario,
  },
  props: {
    multiplos: { type: Boolean, default: true },
    codigoTag: { type: String, default: '' },
    idInstrumento: { type: String, default: '' },
    value: { type: Array, default: () => [] },
  },
  data() {
    return {
      permissoesIntrumentoOcorrencia: {
        funcionalidade: 'InstrumentoOcorrencia',
        acaoInserir: 'Inserir',
        acaoEditar: 'Editar',
        acaoExcluir: 'Excluir',
      },
      indiceAba: 0,
      atualizaListagem: 1,
      atualizaFormulario: 1,
      idRegistro: null,
      participanteClienteId: null,
    };
  },
  watch: {
    indiceAba() {
      if (this.indiceAba === 1) {
        setTimeout(() => {
          this.$refs['formulario-registro-ocorrencia'].resetarValidacao();
        }, 100);
      }
    },
  },
  methods: {
    eventoBotaoVoltar() {
      if (this.indiceAba) {
        this.atualizaListagem++;
        this.indiceAba = 0;
      } else this.$refs['modal-registro-ocorrencia'].fecharModal();
    },
    trocaAbaNovoRegistro(id) {
      if (id) this.idRegistro = id;
      else this.idRegistro = null;
      this.atualizaFormulario++;
      this.indiceAba = 1;
    },
    abrirModal(item) {
      this.participanteClienteId = item.participanteCliente?.id;
      this.atualizaListagem++;
      this.idRegistro = null;
      this.$refs['modal-registro-ocorrencia'].abrirModal();
    },
    fecharModal() {
      this.indiceAba = 0;
      this.$refs['modal-registro-ocorrencia'].fecharModal();
    },
  },
};
</script>
