<template>
  <div>
    <v-form ref="form" class="row mt-2">
      <input-ocorrencia
        v-model="form.ocorrenciaPadrao"
        class="col-12 col-md-4"
        :label="$t('modulos.registro_ocorrencia.formulario.ocorrencia_padrao')"
        obrigatorio
        :multiplos="false"
      />

      <input-colaborador
        v-model="form.colaboradorResponsavel"
        class="col-12 col-md-4"
        :label="
          $t('modulos.registro_ocorrencia.formulario.colaborador_responsavel')
        "
        obrigatorio
        :multiplos="false"
        :participante-cliente-id="participanteClienteId"
      />
      <input-date
        v-model="form.data"
        class="col-12 col-md-4"
        :label="$t('modulos.registro_ocorrencia.formulario.data')"
        obrigatorio
      />
      <input-textarea
        v-model="form.observacao"
        class="col-12"
        :label="$t('modulos.registro_ocorrencia.formulario.observacao')"
        obrigatorio
      />
    </v-form>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao outlined color="secondary" class="mr-2" @click="cancelar">
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import RegistroOcorrenciaService from '@common/services/cadastros/RegistroOcorrenciaService';
import { InputOcorrencia, InputColaborador } from '@components/inputs';
import { RegistroOcorrenciaModel } from '@common/models/cadastros/RegistroOcorrenciaModel';
import helpers from '@common/utils/helpers';
export default {
  components: {
    InputOcorrencia,
    InputColaborador,
  },
  props: {
    id: { type: String, default: '' },
    idInstrumento: { type: String, default: '' },
    participanteClienteId: { type: String, default: '' },
    atualizaFormulario: { type: Number, default: 1 },
  },
  data() {
    return {
      form: new RegistroOcorrenciaModel({}),
      valido: null,
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.tipo_ocorrencia.titulos.editar');
      return this.$t('modulos.tipo_ocorrencia.titulos.novo');
    },
  },
  watch: {
    'form.ocorrenciaPadrao'(valor) {
      if (valor?.value) this.form.ocorrenciaPadraoId = valor?.value;
    },
    'form.colaboradorResponsavel'(valor) {
      if (valor?.value) this.form.colaboradorResponsavelId = valor?.value;
    },
    id() {
      this.atualizaForm();
    },
    atualizaFormulario() {
      this.atualizaForm();
    },
  },
  mounted() {
    if (this.id) {
      this.buscar(this.id);
      helpers.redirecionarSemPermissao(this, 'InstrumentoOcorrencia', 'Editar');
    }
    helpers.redirecionarSemPermissao(this, 'InstrumentoOcorrencia', 'Inserir');
    this.form.instrumentoId = this.idInstrumento;
  },
  methods: {
    resetarValidacao() {
      this.$refs.form.resetValidation();
    },
    atualizaForm() {
      this.iniciaFormulario();
      this.form.instrumentoId = this.idInstrumento;
    },
    iniciaFormulario() {
      if (this.id) this.buscar(this.id);
      this.form = new RegistroOcorrenciaModel({});
    },
    buscar: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      await RegistroOcorrenciaService.buscar(this.id)
        .then(async (res) => {
          this.form = new RegistroOcorrenciaModel(res.data);
          this.form.instrumentoId = this.idInstrumento;
          this.form.data = new Date(res.data.data);
          this.form.instrumento = {
            text: res.data.instrumento.nome,
            value: res.data.instrumento.id,
          };
          this.form.ocorrenciaPadrao = {
            text: res.data.ocorrenciaPadrao.nome,
            value: res.data.ocorrenciaPadrao.id,
          };
          this.form.colaboradorResponsavel = {
            text: res.data.colaboradorResponsavel.nome,
            value: res.data.colaboradorResponsavel.id,
          };
        })
        .catch(() => {
          this.toastErro(
            this.$t('modulos.registro_ocorrencia.erros.id_invalido')
          );
        })
        .finally(() => {
          this.valido = true;
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      if (!this.validarRegistroOcorrencia()) return;
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    validarRegistroOcorrencia: function () {
      if (this.$refs.form) {
        this.valido = this.$refs.form.validate();
        this.indiceAba = 0;
      }
      return this.valido;
    },
    salvar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      RegistroOcorrenciaService.salvar(this.form)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.registro_ocorrencia.cadastro_sucesso`)
          );
          this.fecharLimparCampos();
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    fecharLimparCampos() {
      this.form = new RegistroOcorrenciaModel({});
      this.$emit('fechar-modal');
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.fecharLimparCampos();
      });
    },
  },
};
</script>
